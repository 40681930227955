






































import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { ApiService, PrintService } from "@/core/services";
import { AcademicYearModel, PrintoutBatchGroupModel } from "@/core/webapi";
import { InputAutocompleteCpt, AcademicYearSelectCpt } from "@/core/components";
import { PrintableMentorGroupCpt } from "./components";

@Component({
  components: {
    InputAutocompleteCpt,
    AcademicYearSelectCpt,
    PrintableMentorGroupCpt,
  },
})
export default class GroupsPrintoutView extends AppVue {
  groups: PrintoutBatchGroupModel[] = [];
  academicYears: AcademicYearModel[] = [];
  selectedAcademicYear = new AcademicYearModel({
    id: this.currentAcademicYear,
  });

  async created() {
    await this.loadLookups();
  }

  async onAcademicYearChange() {
    await this.loadMentors();
  }

  print() {
    PrintService.print("print-content", "Mentor Groups Printout");
  }

  private async loadLookups() {
    const response = await ApiService.user().getUserLookups(false, false);
    this.academicYears = response.data.academicYears;
  }

  private async loadMentors() {
    if (!this.selectedAcademicYear?.id) {
      return;
    }

    const buddiesResponse = await ApiService.mentoring().getPrintoutBatchGroups(this.selectedAcademicYear.id);
    this.groups = buddiesResponse.data;
  }
}
