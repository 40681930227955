








































































































































































import { Component, Prop } from "vue-property-decorator";
import _ from "lodash";

import AppVue from "@/AppVue.vue";
import { MentorCollectionModel, MentorGroupsLookups, SimpleLookupModel, MentorsFilter } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";

@Component({
  components: {},
})
export default class ChooseMentorDialogCpt extends AppVue {
  @Prop() lookups: MentorGroupsLookups;
  @Prop() mentorGroupId: number;
  @Prop() courseId: number;
  @Prop() academicYearId: number;
  @Prop() onCancel: () => void;
  @Prop() onSuccess: () => void;

  filter = new MentorsFilter({
    search: "",
    onlyShowNotInGroup: true,
  });
  volunteers: MentorCollectionModel[] = [];
  show = true;
  selectedTags: SimpleLookupModel[] = [];

  async created() {
    this.filter.courseId = this.courseId;
    this.filter.academicYearId = this.academicYearId;
    await this.loadVolutneers();
  }

  async loadVolutneers() {
    const response = await ApiService.mentoring().filterMentors(this.filter, {
      // NOTE: I had to add this query param explicitly because OpenCLI has a bug
      // and it's stripping the "mentors" query param when its value is 0. I'm
      // assuming that it's interpreting 0 as a falsey type and discarding it.
      params: { mentors: this.filter.minMentors },
    });
    this.volunteers = response.data;
  }

  cancel() {
    this.onCancel();
  }

  async selectMentor(volunteerId: number) {
    await ApiService.mentoring().addMentorToGroup(this.mentorGroupId, volunteerId);
    NotificationProvider.success("Mentor added successfully");
    this.$eventHub.$emit("GROUP_MENTORS_CHANGED");
    this.onSuccess();
  }

  async onFilterChange() {
    await this.loadVolutneers();
  }

  async onTagsChange(selectedTags: (SimpleLookupModel | string)[]) {
    this.selectedTags = _.filter(selectedTags, tag => !_.isString(tag)) as SimpleLookupModel[];

    if (this.selectedTags?.length) {
      this.filter.tagIds = _.map(this.selectedTags, tag => tag.id);
    } else {
      this.filter.tagIds = null;
    }

    await this.loadVolutneers();
  }
}
