








































import { Component, Prop } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { MentorGroupsStatsModel } from "@/core/webapi";

@Component({
  components: {},
})
export default class MentorGroupsStatsCpt extends AppVue {
  @Prop() stats: MentorGroupsStatsModel;
  @Prop() onFilterByMentors: () => void;
}
