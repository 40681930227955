










































































import { Component, Prop } from "vue-property-decorator";
import { MenteeModel } from "@/core/webapi";
import AppVue from "@/AppVue.vue";
import { PagedCollection } from "@/core/models";
import { ButtonCpt, ConfirmCpt } from "@/core/components/common";
import { ApiService, NotificationProvider } from "@/core/services";

@Component({
  components: {
    ButtonCpt,
    ConfirmCpt,
  },
})
export default class MenteeTableCpt extends AppVue {
  @Prop() collection: PagedCollection<MenteeModel>;

  showConfirm = false;
  currentMentee?: number;

  confirmDelete(menteeId: number) {
    this.currentMentee = menteeId;
    this.showConfirm = true;
  }

  async deleteMentee(isConfirmed: boolean) {
    this.showConfirm = false;
    if (!isConfirmed || !this.currentMentee) {
      this.currentMentee = null;
      return;
    }

    this.isLoading = true;
    try {
      await ApiService.mentoring().removeMentee(this.currentMentee);
      this.$emit("mentee-deleted");
      NotificationProvider.success("Mentee has been successfully deleted.");
    } finally {
      this.currentMentee = null;
      this.isLoading = false;
    }
  }
}
