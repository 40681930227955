

















































import { Component, Prop } from "vue-property-decorator";
import { BaseFormComponent, InputFieldCpt, ButtonCpt } from "@/core/components/common";
import { UpdateMentorGroupModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";

@Component({
  components: {
    InputFieldCpt,
    ButtonCpt,
  },
})
export default class EditMentorGroupDialogCpt extends BaseFormComponent {
  @Prop({ default: new UpdateMentorGroupModel() }) model: UpdateMentorGroupModel;
  @Prop() onCancel: () => void;
  @Prop() onSuccess: () => void;

  show = true;

  async onSubmitForm(): Promise<void> {
    this.isLoading = true;

    try {
      await ApiService.mentoring().updateMentorGroup(this.model);
      NotificationProvider.success("Mentor group updated successfully");
      this.onSuccess();
    } catch (err) {
      if (err.response && err.response.data) {
        this.model.modelState = err.response.data.modelState;
      }
    } finally {
      this.isLoading = false;
    }
  }

  cancel() {
    this.onCancel();
  }
}
