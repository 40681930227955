












































































































































import { Component, Prop } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { MentorGroupModel, MentorGroupsLookups, UpdateMentorGroupModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import { ConfirmCpt } from "@/core/components";
import ChooseMentorDialogCpt from "./ChooseMentorDialogCpt.vue";
import EditMentorGroupDialogCpt from "./EditMentorGroupDialogCpt.vue";

@Component({
  components: {
    ChooseMentorDialogCpt,
    EditMentorGroupDialogCpt,
    ConfirmCpt,
  },
})
export default class MentorGroupsTableCpt extends AppVue {
  @Prop() lookups: MentorGroupsLookups;
  @Prop() groups: MentorGroupModel[];
  @Prop() courseId: number;
  @Prop() academicYearId: number;

  showAddMentorDialog = false;
  showEditMentorGroupDialog = false;
  addMentorToGroupId = 0;
  editMentorGroupModel?: UpdateMentorGroupModel = null;
  showConfirmDeleteMentorGroup = false;
  showConfirmRemoveMentor = false;
  mentorGroupIdToRemove?: number = null;
  volunteerIdToRemove?: number = null;

  addMentor(groupId: number) {
    this.showAddMentorDialog = true;
    this.addMentorToGroupId = groupId;
  }

  onMentorAddCancel() {
    this.showAddMentorDialog = false;
  }

  onMentorAddSuccess() {
    this.showAddMentorDialog = false;
  }

  removeMentor(volunteerId: number) {
    this.showConfirmRemoveMentor = true;
    this.volunteerIdToRemove = volunteerId;
  }

  async confirmRemoveMentor(isConfirmed: boolean) {
    this.showConfirmRemoveMentor = false;
    if (!isConfirmed) {
      return;
    }

    await ApiService.mentoring().removeMentorFromGroup(this.volunteerIdToRemove);
    NotificationProvider.success("Mentor removed successfully");
    this.volunteerIdToRemove = null;
    this.$eventHub.$emit("GROUP_MENTORS_CHANGED");
  }

  editGroup(group: UpdateMentorGroupModel) {
    this.showEditMentorGroupDialog = true;
    this.editMentorGroupModel = group;
  }

  onGroupEditCancel() {
    this.showEditMentorGroupDialog = false;
    this.editMentorGroupModel = null;
  }

  async onGroupUpdated() {
    this.showEditMentorGroupDialog = false;
    this.editMentorGroupModel = null;
    this.$eventHub.$emit("GROUP_MENTORS_CHANGED");
  }

  async deleteMentorGroup(groupId: number) {
    this.showConfirmDeleteMentorGroup = true;
    this.mentorGroupIdToRemove = groupId;
  }

  async confirmDeleteMentorGroup(isConfirmed: boolean) {
    this.showConfirmDeleteMentorGroup = false;
    if (!isConfirmed) {
      return;
    }

    await ApiService.mentoring().deleteMentorGroup(this.mentorGroupIdToRemove);
    NotificationProvider.success("Group removed successfully");
    this.mentorGroupIdToRemove = null;
    this.$eventHub.$emit("GROUP_MENTORS_CHANGED");
  }
}
