













































import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { ApiService, NotificationProvider, PrintService } from "@/core/services";
import { AcademicYearModel, MyMentorGroupModel } from "@/core/webapi";
import { InputAutocompleteCpt, AcademicYearSelectCpt } from "@/core/components";
import { PrintableMentorGroupCpt } from "./components";

@Component({
  components: {
    InputAutocompleteCpt,
    AcademicYearSelectCpt,
    PrintableMentorGroupCpt,
  },
})
export default class MyMentorGroupView extends AppVue {
  model = new MyMentorGroupModel({ isApproved: true });
  academicYears: AcademicYearModel[] = [];
  selectedAcademicYear = new AcademicYearModel({
    id: this.currentAcademicYear,
  });

  async created() {
    await this.loadLookups();
  }
  copyEmailsToClipboard() {
    const emails = this.model.members.map(p => p.email);
    navigator.clipboard.writeText(emails.join(";"));
    NotificationProvider.success(`${emails.length} emails copied to clipboard!`);
  }
  async onAcademicYearChange() {
    await this.loadMentors();
  }

  print() {
    PrintService.print("print-content", "My Mentor Group");
  }

  private async loadLookups() {
    const response = await ApiService.user().getUserLookups(false, false);
    this.academicYears = response.data.academicYears;
  }

  private async loadMentors() {
    if (!this.selectedAcademicYear?.id) {
      return;
    }

    const buddiesResponse = await ApiService.mentoring().getMyMentorGroup(this.selectedAcademicYear.id);
    this.model = buddiesResponse.data;
  }
}
