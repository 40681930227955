

































































import { Component } from "vue-property-decorator";
import { MenteeModel, MenteeFilterModel, MenteeLookups } from "@/core/webapi";
import BaseGridComponent from "@/core/components/common/grid/BaseGridComponent.vue";
import { ApiService } from "@/core/services";
import { GridPagerCpt, GridSelectFilterCpt, GridSearchCpt } from "@/core/components/common/grid";
import { ButtonCpt, AcademicYearSelectCpt } from "@/core/components/common";
import { ImportMenteeDialogCpt, MenteeTableCpt } from "./components";

@Component({
  components: {
    ImportMenteeDialogCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    GridSearchCpt,
    ButtonCpt,
    AcademicYearSelectCpt,
    MenteeTableCpt,
  },
})
export default class MenteeSearchView extends BaseGridComponent<MenteeModel> {
  showImportMentees = false;
  lookups = new MenteeLookups();

  hasGrid() {
    return !!this.grid;
  }

  async created() {
    await this.loadLookups();
    this.load();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.mentoring().getMenteeFilteringLookups();
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  importMenteeClosed(result: boolean) {
    this.showImportMentees = false;
    if (result) {
      this.load();
    }
  }

  getNewFilter() {
    const query = this.$route.query as any;
    // If academic year in URL, it must update the localStorage
    const academicYear = Number(query.academicYearId);
    if (academicYear && academicYear !== this.currentAcademicYear) {
      this.currentAcademicYear = academicYear;
    }

    return new MenteeFilterModel({
      pageSize: Number(query.pageSize) || 20,
      page: Number(query.page) || 1,
      academicYearId: this.currentAcademicYear,
      search: query.search || "",
      sortBy: query.sortBy || "FirstName",
      sortOrder: query.sortOrder || "asc",
    });
  }

  private load() {
    this.initialize(
      // eslint-disable-next-line
      filter => ApiService.mentoring().filterMentees(filter),
      "mentees",
      this.grid?.filter || this.getNewFilter(),
    );
  }
}
