































































































import { Component } from "vue-property-decorator";
import _ from "lodash";
import AppVue from "@/AppVue.vue";
import { AcademicYearSelectCpt, ButtonCpt } from "@/core/components/common";
import { MentorGroupModel, MentorGroupsFilter, MentorGroupsLookups, MentorGroupsStatsModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import { MentorGroupsStatsCpt, AddMentorGroupDialogCpt, MentorGroupsTableCpt } from "./components";

@Component({
  components: {
    AcademicYearSelectCpt,
    ButtonCpt,
    MentorGroupsStatsCpt,
    AddMentorGroupDialogCpt,
    MentorGroupsTableCpt,
  },
})
export default class MentorGroupsView extends AppVue {
  filter = new MentorGroupsFilter({
    courseId: null,
    mentors: null,
    yearId: null,
  });
  lookups = new MentorGroupsLookups();
  stats = new MentorGroupsStatsModel();
  groups: MentorGroupModel[] = [];

  showAddGroup = false;

  async created() {
    this.loadFilter();
    await this.loadLookups();
    await this.loadData();

    this.$eventHub.$on("GROUP_MENTORS_CHANGED", async () => {
      await this.loadData();
    });
  }

  loadFilter() {
    const query = this.$route.query as any;
    if (query) {
      // If academic year in URL, it must update the localStorage
      const academicYear = Number(query.yearId);
      if (academicYear && academicYear !== this.currentAcademicYear) {
        this.currentAcademicYear = academicYear;
      }

      const courseId = Number(query.courseId);
      const mentors = Number(query.mentors);
      this.filter.courseId = !Number.isNaN(courseId) ? courseId : null;
      this.filter.mentors = !Number.isNaN(mentors) ? mentors : null;
      this.filter.yearId = academicYear || null;
    }
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const result = await ApiService.mentoring().getMentorGroupsFilteringLookups();
      this.lookups = result.data;
      this.filter.courseId = this.filter.courseId || this.lookups.courses[0]?.id;
      this.filter.mentors = !Number.isNaN(this.filter.mentors)
        ? this.filter.mentors
        : this.lookups.noOfMentors[0]?.amount;
    } finally {
      this.isLoading = false;
    }
  }

  async loadStats() {
    this.isLoading = true;
    try {
      const result = await ApiService.mentoring().getMentorGroupsStats({
        yearId: this.filter.yearId,
        courseId: this.filter.courseId,
      });
      this.stats = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  async loadGroups() {
    this.isLoading = true;
    try {
      const result = await ApiService.mentoring().filterMentorGroups(this.filter, {
        // NOTE: I had to add this query param explicitly because OpenCLI has a bug
        // and it's stripping the "mentors" query param when its value is 0. I'm
        // assuming that it's interpreting 0 as a falsey type and discarding it.
        params: { mentors: this.filter.mentors },
      });
      this.groups = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  async loadData() {
    this.updateRoute();
    await this.loadStats();
    await this.loadGroups();
    this.$forceUpdate();
  }

  onFilterByMentors(amount: number) {
    this.filter.mentors = amount;
    this.loadData();
  }

  addGroup() {
    this.showAddGroup = true;
  }

  onGroupAddCancel() {
    this.showAddGroup = false;
  }

  async onGroupAdded() {
    this.showAddGroup = false;
    await this.loadData();
  }

  async onFilterChange() {
    await this.loadData();
  }

  updateRoute() {
    const definedParams = this.getDefinedParams();
    this.$router.push({ name: "mentor-groups", query: definedParams as any }).catch(error => {
      // SEE: https://stackoverflow.com/a/59431264/413785
      if (error.name !== "NavigationDuplicated") {
        throw error;
      }
    });
  }

  private getDefinedParams() {
    return _.omitBy(this.filter, _.isNil);
  }
}
