

















































import { Component, Prop } from "vue-property-decorator";
import { BaseFormComponent, InputFieldCpt, ButtonCpt } from "@/core/components/common";
import { CreateMentorGroupModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";

@Component({
  components: {
    InputFieldCpt,
    ButtonCpt,
  },
})
export default class AddMentorGroupDialogCpt extends BaseFormComponent {
  @Prop() yearId: number;
  @Prop() courseId: number;
  @Prop() onCancel: () => void;
  @Prop() onSuccess: () => void;

  model = new CreateMentorGroupModel();
  show = true;

  async onSubmitForm(): Promise<void> {
    this.isLoading = true;
    this.model.academicYearId = this.yearId;
    this.model.courseId = this.courseId;

    try {
      await ApiService.mentoring().createMentorGroup(this.model);
      NotificationProvider.success("Mentor group added successfully");
      this.onSuccess();
    } catch (err) {
      if (err.response && err.response.data) {
        this.model.modelState = err.response.data.modelState;
      }
    } finally {
      this.isLoading = false;
    }
  }

  cancel() {
    this.onCancel();
  }
}
