




























































import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { ApiService, NotificationProvider } from "@/core/services";
import { AcademicYearModel, MyBuddiesFormModel } from "@/core/webapi";
import { InputAutocompleteCpt, AcademicYearSelectCpt } from "@/core/components";

@Component({
  components: {
    InputAutocompleteCpt,
    AcademicYearSelectCpt,
  },
})
export default class MyBuddiesView extends AppVue {
  loading = false;
  model = new MyBuddiesFormModel();
  academicYears: AcademicYearModel[] = [];
  selectedAcademicYear = new AcademicYearModel({
    id: this.currentAcademicYear,
  });

  async created() {
    await this.loadLookups();
  }

  async save() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      await ApiService.mentoring().postMyBuddies({
        academicYearId: this.selectedAcademicYear.id,
        buddy1VolunteerId: this.model.buddy1VolunteerId,
        buddy2VolunteerId: this.model.buddy2VolunteerId,
      });
      NotificationProvider.success("Buddies nominated successfully.");
    } catch {
      NotificationProvider.error("Something went wrong. Please contact the system admin");
    } finally {
      this.loading = false;
    }
  }

  async onAcademicYearChange() {
    await this.loadBuddies();
  }

  private async loadLookups() {
    this.loading = true;
    try {
      const response = await ApiService.user().getUserLookups(false, false);
      this.academicYears = response.data.academicYears;
    } finally {
      this.loading = false;
    }
  }

  private async loadBuddies() {
    if (!this.selectedAcademicYear?.id) {
      return;
    }

    this.loading = true;
    try {
      const buddiesResponse = await ApiService.mentoring().getMyBuddies(this.selectedAcademicYear.id);
      this.model = buddiesResponse.data;
    } finally {
      this.loading = false;
    }
  }
}
